.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* poppins-regular - latin */

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../src/fonts/poppins-v20-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("../src/fonts/poppins-v20-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("../src/fonts/poppins-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("../src/fonts/poppins-v20-latin-regular.woff") format("woff"),
    /* Modern Browsers */
    url("../src/fonts/poppins-v20-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("../src/fonts/poppins-v20-latin-regular.svg#Poppins") format("svg");
  /* Legacy iOS */
}

* {
  font-family: "Poppins";
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.box-border {
  border-bottom-right-radius: 0px !important;
}

#sidebar .item-text {
  color: #afb5b7;
}

#sidebar {
  position: absolute;
}

/* @media only screen and (max-width: 768px) {
    #sidebar {
    display: none !important;
  } 
} */

#sidebar .active-item .item-text {
  color: #008fd7;
  position: relative;
  font-weight: 600;
  color: #008fd7 !important;
}

#sidebar .active-item {
  background: linear-gradient(90.13deg,
      rgba(0, 117, 252, 0.26) -18%,
      rgba(0, 117, 252, 0) 103.91%);
  color: #008fd7 !important;
}

#sidebar .active-item .icon {
  filter: invert(100%) sepia(16%) saturate(7463%) hue-rotate(222deg) brightness(119%) contrast(115%);
}

.active-item .MuiListItemText-primary {
  color: #008fd7 !important;
}

.active-item::before {
  position: absolute;
  content: "";
  left: 0px;
  background-color: #008fd7;
  border-radius: 0px 6px 6px 0px;
  width: 5px;
  height: 50px;
  top: 0px;
}

.dropdown-text {
  color: #282c34;
  padding-left: 1rem;
  margin-left: 2.7rem;
  font-size: 14px;
  position: relative;
}

.dot {
  color: #282c34;
  padding-left: 1rem;
  margin-left: 2.7rem;
  font-size: 14px;
  position: relative;
  content: "";
}

#topbar .dot::after {
  color: #282c34;
  font-size: 14px;
  position: absolute;
  content: "";
  top: 9px;
  width: 6px;
  height: 7px;
  border-radius: 100%;
  background: rgba(175, 181, 183, 0.5);
  left: 5px;
}

#topbar .h-line::after {
  content: "";
  left: 70px;
  top: 0px;
  color: rgba(175, 181, 183, 0.5);
  border: 1px solid rgba(175, 181, 183, 0.5);
  position: absolute;
  height: 48px;
  width: 3px;
  background: rgba(175, 181, 183, 0.5);
}

#topbar .v-line:first-child::after {
  top: 23px;
}

#topbar .v-line:last-child::before {
  top: -48px;
}

#topbar .v-line::after {
  content: "";
  left: 71px;
  position: absolute;
  background: rgba(175, 181, 183, 0.5);
  width: 19px;
  height: 2px;
  top: 23px;
}

#topbar .v-line:last-child::after {
  top: 22px;
}

span.MuiTypography-root.MuiTypography-body1 {
  color: #afb5b7;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #008fd7;
}

.dx-datagrid-headers .dx-datagrid-content {
  border-radius: 4px;
  margin-bottom: -1px;
}

.dropdown-text::after {
  content: "";
  /* border-left: 1px solid; */
  position: absolute;
  left: 0px;
  height: 30px;
}

/* Handle */

.dx-scrollable-scroll-content {
  background: #008fd7;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
  height: 8px !important;
}

/* Handle on hover */

.dx-scrollable-scroll-content:hover {
  background: #008fd7;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #008fd7;
  border-radius: 8px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #008fd7;
}

.active-item {
  filter: invert(100%) sepia(16%) saturate(7463%) hue-rotate(222deg) brightness(119%) contrast(115%);
}

#sidebar {
  display: none;
}

#topbar .list-item:first-child .h-line::after {
  top: 23px;
}

#topbar .list-item:last-child .h-line::after {
  top: -24px;
}

.dull {
  background: #00000042;
  filter: brightness(75%);
}

.wraapeer {
  flex-wrap: unset;
}

@media only screen and (max-width: 768px) {
  .wraapeer {
    flex-wrap: wrap;
  }
}

.braapeer {
  flex-wrap: unset;
}

@media only screen and (max-width: 768px) {
  .braapeer {
    flex-wrap: wrap;
  }
}

.three-dot {
  display: inline-block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.gap-between {
  margin-bottom: 20px;
}

.tooltip-design {
  background: white;
  color: black;
  width: 150px;
  height: 45px;
  border-radius: 5px;
}

.render-text {
  margin-bottom: 0px !important;
  font-size: 12px;
}

.date-size {
  width: 100% !important;
  height: 40px !important;
  border-radius: 6px !important;
  background: #f9fafb !important;
  border-color: #f5f2f2 !important;
}

/* .dot{
    content: '';
    position: absolute;
    border-radius: 50px;
    height: 6px;
    width: 6px;
    background-color: rgba(175, 181, 183, 0.5);
    left: 6px;
    top: 4px;
} */
.h-line>div {
  padding-left: 40px;
}

.dx-datagrid-text-content p {
  margin: 0px !important;
  padding: 5px 0px;
}

.dx-column-lines {}

#sidebar {
  /* z-index: 1 !important; */
}

#ReactSimpleImageViewer {
  position: fixed;
  z-index: 100 !important;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block !important;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block !important;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;

}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: "" !important;
  display: none !important;
}


.PhoneInputCountrySelectDropdown {
  position: relative;
  max-height: 100px !important;
  /* Adjust height to show 10 countries */
  overflow-y: auto !important;
  /* Enable vertical scrolling */
}