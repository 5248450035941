@media only screen and (min-width: 697px) {
    .input-size{
        flex: 1;
    }
  }
  .loader {    
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 10px;
    height: 10px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}