@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Poppins', sans-serif;
    }
}

.dx-datagrid-rowsview .dx-row,
.dx-datagrid .dx-column-lines>td,
.dx-datagrid-borders>.dx-datagrid-headers {
    border: none !important;
}

.dx-datagrid-headers .dx-datagrid-content {
    background-color: #E5F0FE;
}

.dx-datagrid-headers .dx-datagrid-content .dx-header-row>td {
    color: #0075FC;
    margin-top: 12px;
    font-size: 14px !important;
}

.dx-datagrid-rowsview .dx-row>td>p {
    font-size: 14px !important;
}

.dx-datagrid-nowrap,
.dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
    white-space: pre-line;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
    vertical-align: text-bottom !important;
}

.submit-btn {
    @apply text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 w-full rounded-md py-3 px-8 text-center text-base font-semibold outline-none;
}

/* p {
    margin-top: 0;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
} */

.PhoneInputInput {
    outline: none !important;
}