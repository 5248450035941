.mrs-0 {
    margin-right: 0px !important;
}


#sidebar .dropdown-items{
    position: relative;
    margin: 20px 0px;
    display: flex;
    align-items: center;
}
#sidebar .dropdown-items::after{
    
    content: '';
    left: 50px;
    top: 0px;
    color: rgba(175, 181, 183, 0.5);
    border: 1px solid rgba(175, 181, 183, 0.5);
    position: absolute;
    height: 35px;
    width: 3px;
    background: rgba(175, 181, 183, 0.5);

}

#sidebar .dropdown-items:first-child::after{
    top: 9px;
}

#sidebar .dropdown-items:last-child::after{
    top: -19px;
}

#sidebar .dropdown-link{
    margin-left: 18px;
    position: relative;
}

#sidebar .dropdown-link::after{
    content: '';
    left: 34px;
    position: absolute;
    background: rgba(175, 181, 183, 0.5);
    width: 15px;
    height: 2px;
    top: 9px;
}
#sidebar .dropdown-items:last-child .dropdown-link::after{
    top: 15px;
}


#sidebar  .dropdown-text::after{
    content: '';
    position: absolute;
    border-radius: 50px;
    height: 6px;
    width: 6px;
    background-color: rgba(175, 181, 183, 0.5);
    left: 6px;
    top: 4px;
}
#sidebar  .dropdown-items:last-child  .dropdown-text::after{
    top: 10px;
}



