/* #report-table .dx-header-row>td {
    background-color: #E5F0FE !important;
    color: #0075FC !important;
    max-width: 50px;
}

#report-table .dx-column-lines td {
    padding: 20px 2px;
}

.dx-scrollable-container {
    margin-top: 20px;
}

#report-table .dx-datagrid .dx-column-lines>td {
    border: none !important;
    border-bottom: 1px solid #ccc !important;
}

.dx-datagrid-content .dx-datagrid-table,
.dx-datagrid-rowsview .dx-row,
.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers+.dx-datagrid-rowsview,
.dx-datagrid-rowsview.dx-datagrid-after-headers {
    border: none !important;
}



#report-table .dx-visibility-change-handler {
    width: 100%;
}

.dx-editor-with-menu {
    display: flex;
    align-items: center;
}

 */
#report-table .dx-datagrid-header-panel{
    z-index: 4;
}



/* @media only screen and (max-width: 768px) {
    .margin-style{
        margin-left: 60px;
    }
  } */


/* .dx-checkbox-icon{
    top: -10px;
    height: 27px;
} */

